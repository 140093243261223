import React from 'react';
import styled from 'styled-components';
import { color } from 'styled-system';

import { Flex, Icon, Search } from '@xceedsrl/jukebox';

const StyledInput = styled.input`
  ${color}
  line-height: 24px;
  font-weight: 900;
  font-size: 18px;
  border: none;
  background-color: transparent;
  width: inherit;
  ::placeholder {
    color: #6e7a83;
  }
  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    appearance: none;
  }
`;

const SearchWrapper = styled(Flex)`
  width: 100%;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
`;

const SearchBar = React.forwardRef(function SearchBar(props, ref) {
  return (
    <SearchWrapper>
      <Icon mx={2} my="auto" color="text.secondary">
        <Search />
      </Icon>
      <StyledInput ref={ref} type="search" color="brandDark" {...props} />
    </SearchWrapper>
  );
});

export default React.memo(SearchBar);
