import React from 'react';

import { Flex, Icon, Search, SecondaryTitle } from '@xceedsrl/jukebox';

import { useTranslation } from 'i18n/';

function EmptySearch(props) {
  const { t } = useTranslation();
  return (
    <Flex
      width={1}
      height="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        borderRadius="50%"
        backgroundColor="background.mercury"
        padding={30}
        mb={2}
      >
        <Icon color="brandLight" fontSize={11}>
          <Search />
        </Icon>
      </Flex>
      <SecondaryTitle>{t('common:emptySearch')}</SecondaryTitle>
    </Flex>
  );
}

export default React.memo(EmptySearch);
