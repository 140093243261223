import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { animated, Spring } from '@react-spring/web';
import noScroll from 'no-scroll';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Avatar,
  Box,
  Carousel,
  Container,
  Flex,
  Grid,
  SecondaryTitle,
  TertiaryTitle,
  Text,
  useEventListener,
} from '@xceedsrl/jukebox';

import { useTranslation } from 'i18n/';
import { useCities } from 'store/cities';

import Link from 'components/CustomLink';

import { LinkedCity } from './CityWrappers';
import CloseButton from './CloseButton';
import EmptySearch from './EmptySearch';
import SearchBar from './SearchBar';

const PADDING_TOP = [3, 5];
const GAP = [2, 3];

const Modal = styled(animated(Flex))`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  will-change: transform;
`;

const SearchContent = styled(Box)`
  flex: 1;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: overlay;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const CitiesContext = React.createContext();

function filterCitiesByName(cityList, query) {
  const queryReg = new RegExp(query, 'gi');
  return cityList?.filter(city => queryReg.test(city.name));
}

function groupCitiesByCountry(cityList) {
  const countriesObj = cityList?.reduce((countries, city) => {
    const { name, slug } = city.country;
    if (!countries[name]) {
      countries[name] = { name, slug, cities: [] };
    }
    countries[name].cities.push(city);
    return countries;
  }, {});
  const countryArr =
    countriesObj && Object.values(countriesObj).sort((a, b) => (a.name < b.name ? -1 : 1));

  return countryArr;
}

const City = React.memo(({ name, slug, coverUrl }) => {
  const { isOpen, onModalClose } = useContext(CitiesContext);
  return (
    <Link onClick={onModalClose} href="/[geo]" as={`/${slug}`} aria-label={name}>
      <Grid gridTemplateColumns="auto 1fr" gridGap={2} alignItems="center">
        <Avatar size="tiny" src={isOpen ? coverUrl : null} alt={name} />
        <Text>{name}</Text>
      </Grid>
    </Link>
  );
});

City.propTypes = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  coverUrl: PropTypes.string.isRequired,
};

function Country({ name, slug, cities: countryCities }) {
  const { onModalClose } = useContext(CitiesContext);
  const Cities = useMemo(
    () => countryCities.map(city => <City key={city.id} {...city} />),
    [countryCities]
  );
  return (
    <Box mb={4}>
      <Box width="min-content">
        <Link onClick={onModalClose} href="/[geo]" as={`/${slug}`} aria-label={name}>
          <TertiaryTitle mb={2}>{name}</TertiaryTitle>
        </Link>
      </Box>
      <Grid
        gridTemplateColumns={['1fr', 'repeat(3, 1fr)', 'repeat(6, 1fr)']}
        gridGap={3}
        alignItems="center"
      >
        {Cities}
      </Grid>
    </Box>
  );
}

Country.propTypes = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  cities: PropTypes.arrayOf(PropTypes.shape(City.propTypes)).isRequired,
};

function CitiesModal({ onModalClose, isOpen, ...props }) {
  const [query, setQuery] = useState('');
  const { t } = useTranslation();

  const [{ cities, popularCities }] = useCities();

  const onChange = useCallback(e => setQuery(e.target.value), [setQuery]);

  useEventListener('keydown', e => {
    if (e.keyCode === 27) {
      e.stopPropagation();
      onModalClose();
    }
  });

  const filteredCities = useMemo(() => {
    const filtered = filterCitiesByName(cities, query);
    return groupCitiesByCountry(filtered);
  }, [cities, query]);

  const CarouselList = useMemo(
    () =>
      !query ? (
        <Flex flexDirection="column" width={1}>
          <Container mb={3}>
            <SecondaryTitle>{t('common:popularDestinations')}</SecondaryTitle>
          </Container>
          <Carousel itemsToShow={[2, 3, 5]}>
            {popularCities?.map(city => (
              <LinkedCity key={city.id} city={city} onClick={onModalClose} />
            ))}
          </Carousel>
        </Flex>
      ) : null,
    [query, popularCities]
  );

  const CountryList = useMemo(
    () =>
      filteredCities?.length ? (
        filteredCities.map((country, id) => <Country key={id} {...country} />)
      ) : (
        <EmptySearch justifySelf="center" />
      ),
    [filteredCities]
  );

  useEffect(() => {
    if (isOpen) noScroll.on();
    else noScroll.off();
  }, [isOpen]);

  return (
    <Spring native to={{ transform: `translate3d(0,${isOpen ? '0%' : '-100%'},0)` }}>
      {style => (
        <Modal
          id="cities-modal"
          paddingX={0}
          paddingTop={PADDING_TOP}
          backgroundColor="brandLight"
          width="100%"
          height="100%"
          maxWidth="100%"
          style={style}
          {...props}
        >
          <CitiesContext.Provider value={{ isOpen, onModalClose }}>
            <Container>
              <Grid gridGap={GAP} gridTemplateColumns="1fr auto" alignItems="center" mb={5}>
                <SearchBar placeholder={t('common:citySearch')} value={query} onChange={onChange} />
                <CloseButton onClick={onModalClose} fontSize={32} />
              </Grid>
            </Container>
            <SearchContent>
              {CarouselList}
              <Container paddingBottom={2}>{CountryList}</Container>
            </SearchContent>
          </CitiesContext.Provider>
        </Modal>
      )}
    </Spring>
  );
}

CitiesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
};

export default React.memo(CitiesModal);
